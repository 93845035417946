import React, {useEffect, useContext, useState,  useMemo }  from "react";
import { useNavigate  } from 'react-router-dom';
import {     
    List,
    Datagrid,
    TextField,
    ReferenceField,   
    ReferenceInput,
    TextInput, 
    FunctionField,
    SelectInput,
    useRefresh,
    Filter,
    useRecordContext
} from 'react-admin';
import axios from "../axiosConfig";
import {StaffRowStyle } from "../utils/CustomRowStyle";
import { isWeekend, isPast, parseISO, format } from 'date-fns';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
    Button,
    Backdrop,
    CircularProgress
}from '@mui/material';

import { UserContext } from "../auth/UserContext";
import  ImageRenderer  from "../utils/ImageRenderer";

const Film= (props) =>{     
    const navigate = useNavigate();
    const authUser = useContext(UserContext);
    const refresh = useRefresh();
    const [isLoading, setIsLoading] = useState(false); 

    const isManager = authUser.teams.some(item => item.role === 3);
    const userTeams = authUser.teams.filter(item => item.role>=0 ).map(item => item.teamId);
    const [filter, setFilter] = useState({});
    const defaultFilter = isManager?{ task:{job: {team: { managers: { has: authUser.id } }}}, status:'Open'}:{staffId:authUser.id,status:'Open'};

    const [jobId, setJobId] = useState(null)
    const [openChangeDate, setOpenChangeDate] = useState(false);
    const [scheduleDate, setScheduleDate] = useState(null);  
    const [disableChangeDate, setDisableChangeDate] = useState(true);  

    useEffect(() => {// remove all filters when user leaves the page
        return () => {
            localStorage.removeItem('RaStore.workspace/film.listParams');
        };
    },[])

    const TitleField = () => {
        const record = useRecordContext();
        return <span>{record.task.job.name}</span>;
    }

    const FilmFilter = () => {
        const status = useMemo(() => [
            { id: 'Open', name: 'Todo' },
            { id: 'Completed', name: 'Completed' }, 
        ], []);    
        return (
            <Filter >
                <SelectInput label="State" source="status" choices={status} alwaysOn onChange={filterWithStatus} emptyText="All"/>
                {isManager?<ReferenceInput  source="staffId" reference="user" alwaysOn filter={{taskTypes:{some:{taskTypeId:1, active:true, teamId:{in:userTeams}}} ,active:true}} sort={{ field: 'firstName', order: 'DESC' }}>
                    <SelectInput label="Creator" optionText="firstName" optionValue="id" emptyText="All" onChange={filterWithStatus}/>
                </ReferenceInput>:null}
                <TextInput label="Title" source="title" onChange={filterWithStatus} alwaysOn />
            </Filter>
        );
    };
    const filterWithStatus = () => {
        if(isManager){setFilter({ task:{job: {team: { managers: { has: authUser.id } }}}})}
        else{setFilter( {task:{job:{teamId : {  in: userTeams } }}} )}
    }

    const RawFileField = ({type}) => {
        const rawFolder = type === 'video' ? 'rawVideosFolder' : 'rawImagesFolder';
        const record = useRecordContext();
        if (record.status === "Completed" || (record.info && record.info[type])) {
            return <a href={"https://drive.google.com/drive/folders/"+record.task.job.jobInfo[rawFolder]} target="_blank" rel="noreferrer">Raw {type}s</a>;
        } else if(record.staffId === authUser.id){
            return <ButtonGroup variant="contained" >
                <Button variant="contained" color="primary" onClick={()=>window.open("https://drive.google.com/drive/folders/"+record.task.job.jobInfo[rawFolder],"_blank")}>Upload</Button>
                <Button variant="contained" color="info" onClick={()=>{handleSubmitRawFile(type, record)}}>Submit</Button>
            </ButtonGroup>
        } else return <span>Working</span>;
    };

    const handleSubmitRawFile = async (type, record) => {
        if(!window.confirm('Are you sure you have uploaded the entire file and that the process has been completed?')) return;
        setIsLoading(true);
        const isFinish = record.info?true:false
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/film/${record.id}`,  {type:type, data: {info: {...record.info, [type]:true}}});   
            if(record.info != null){
                await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/film/${record.id}`,  {data:{status: 'Completed', info: {...record.info,"userSubmit":authUser.id}}});   
            }  
        }
        catch(err){
            console.log(err);
        } finally {
            refresh();
            setIsLoading(false);
            if(!isFinish){
                navigate('/workspace/edit');
            }
        }

    };

    const handleSwitchDate = (job) =>{
        setScheduleDate(job.filmingDate ? format(new Date(job.filmingDate), 'yyyy-MM-dd') : '');
        setOpenChangeDate(true);
        setJobId(job.id);        
    }
    const handleChangeDate = (e)=>{
        const selectedDate = parseISO(e.target.value); // Chuyển đổi chuỗi ngày ISO thành đối tượng Date
        const today = new Date();
        if (isWeekend(selectedDate) || isPast(selectedDate, today)) {
            alert("The selected date is invalid");
            setScheduleDate(null);
            setDisableChangeDate(true)
            return; // Giữ nguyên giá trị cũ
        }
        setScheduleDate(e.target.value);
        setDisableChangeDate(false);
    }
    const changeDate = async () => {
        try{
            handleCloseDialog(); 
            setIsLoading(true);
            let localDate = parseISO(scheduleDate);
            localDate.setHours(8,0,0,0);
            let utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);     
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/film/changeDate/${jobId}`, {filmingDate: utcDate.toISOString()});   
        }
        catch(err){
            console.log(err);
        } finally {
            refresh();
            setDisableChangeDate(false);
            setIsLoading(false);
        }          
    };
    const handleCloseDialog =()=>{
        setOpenChangeDate(false); 
        setScheduleDate(null);
        setJobId(null);
    }

    return (
        <List {...props} filter={filter} filterDefaultValues={defaultFilter}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
            <FilmFilter />
            <Dialog
                open={openChangeDate}
                onClose={handleCloseDialog}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Change Film Date</DialogTitle>
                <DialogContent>
                    <input 
                        type="date" 
                        value ={scheduleDate || ''}
                        onChange={(e)=>{handleChangeDate(e)}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
                    <Button onClick={()=>changeDate()} color="primary" disabled={disableChangeDate} >Change Date</Button>
                </DialogActions>
            </Dialog>
            <Datagrid rowStyle={StaffRowStyle(authUser, userTeams)} bulkActionButtons={false}> 
                <FunctionField label="Idea Thumbnail" render={(record) => (
                    <ImageRenderer jobInfo={record.task.job.jobInfo} />
                )}/>
                <TitleField label="Title" source="title"  sortable/> 
                <FunctionField label="" render={record =>(
                    <a href={`https://docs.google.com/document/d/${record.task.job.jobInfo.script}/edit`} target="_blank" rel="noreferrer">View Script</a>
                )} />   
                <FunctionField label="" render={record =>(
                    <a href={`/#/job/${record.task.job.id}/show`} target="_blank" rel="noreferrer">View Project</a>
                )} />
                <ReferenceField source="staffId" reference="User" label="Creator">
                    <TextField label="User" source="firstName" />
                </ReferenceField>              
                <TextField source="status"/>
                <FunctionField label="Film At" render={record =>{
                    const localDate = new Date(record.task.job.filmingDate);
                    return format(localDate, 'yyyy-MM-dd');
                }} />
                <RawFileField label="Raw Videos" type="video"/>                
                <RawFileField label="Raw Images" type="image"/>                
                <FunctionField label="Actions" render={(record) =>{
                    const indexInTeam = userTeams.indexOf(record.task.job.team.id);
                    const isLeader = authUser.teams[indexInTeam] && authUser.teams[indexInTeam].role > 1;
                    const isFilm = record.info?true:false;
                    if (record.status === "Completed") return <span>Filmed</span>;
                    if(isLeader && record.staffId !== authUser.id && !isFilm){
                        return <Button variant="contained" color="primary" onClick={()=>handleSwitchDate(record.task.job)} >Switch Date</Button>
                    }
                    return (
                        <div>
                            {isLeader && !isFilm && <Button variant="contained" color="primary" onClick={()=>handleSwitchDate(record.task.job)} >Switch Date</Button> }
                        </div>
                    )
                }}/>
            </Datagrid>
        </List>
    )};

export default Film
