import React, { useState, useContext,  useCallback   } from 'react';
import { 
    useRecordContext, 
    useRefresh,
    SimpleForm,
    SelectArrayInput,
    useListContext
 } from 'react-admin';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from '../axiosConfig';
import { UserContext } from "../auth/UserContext";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const InfoField = ({ source, setLoading, allInfo }) => {
    const record = useRecordContext();
    const { selectedIds } = useListContext(); 
    const hasSelection = selectedIds && selectedIds.length > 0;
    const [isEditing, setIsEditing] = useState(false);
    const [info, setInfo] =  useState(record.info? record.info : []);
    const refresh = useRefresh();
    const authUser = useContext(UserContext);
    const canPost = record.team.info.userPosts && record.team.info.userPosts.includes(authUser.id);
   
    const handleDoubleClick = () => {
        if(hasSelection) return;
        if (canPost) {setIsEditing(true)}
    };

    const handleSaveVideoInfo = async () => {
        setLoading(true);
        try {
            setIsEditing(false);
            await axios.put(`${process.env.REACT_APP_API_URL}api/video/${record.id}`, { info: info});            
        } catch (error) {
            console.error(error);
        } finally  {
            refresh();
            setLoading(false);
        }
    };

    const handleCancel = useCallback(() => {
        setIsEditing(false);
        setInfo(record.info);
    },[record.info]);

    return(
        <>
            <Dialog
                open={isEditing}
                onClose={handleCancel}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Select Info:</DialogTitle>
                <DialogContent >
                    <SimpleForm toolbar={null}>
                        <SelectArrayInput
                            label="Video Info"
                            source="info"
                            choices={allInfo}
                            onChange={(e) => setInfo(e.target.value)}
                            defaultValue={record.info}
                            alwaysOn
                        />
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup>
                        <Button onClick={handleSaveVideoInfo} variant="contained" color="primary" startIcon={<SaveIcon />}>Save</Button>
                        <Button onClick={handleCancel} variant="contained" color="error" startIcon={<CancelIcon />} > Cancel </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
            {canPost? (record.info && record.info.length >0)? 
                <div onDoubleClick={handleDoubleClick} style={{ cursor: 'pointer' }} title='Double Click to Change'>
                    {record.info.join(', ')}
                </div>
                :
                <span onClick={handleDoubleClick} style={{ cursor: 'pointer' }}>{canPost?'Click To Set':''}</span>
            :
            <span>{(record.info && record.info.length >0)? record.info.join(', ') : 'not Set' }</span>
            }
        </>
    )
};

export default InfoField;
