import React, { useContext, useEffect, useState, useMemo }  from "react";
import {     
    List,
    Datagrid, 
    ReferenceField,
    ReferenceInput,
    TextInput,
    TextField, 
    FunctionField,
    SelectInput,
    useRefresh,
    Filter,
    useRecordContext
} from 'react-admin';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    ButtonGroup,
    Backdrop,
    CircularProgress,   
    Select, 
    MenuItem, 
    FormControl, 
    InputLabel 
} from '@mui/material';

import axios from "../axiosConfig";
import useDrivePicker from "react-google-drive-picker";
import { UserContext } from "../auth/UserContext";
import { CustomRush } from "../utils/CustomField";

const Thumb= (props) =>{  
    const [openPicker] = useDrivePicker();
    const refresh = useRefresh();
    const [isLoading, setIsLoading] = useState(false);
    const authUser = useContext(UserContext);
    const [state, setState] = useState({ 
        indexImage : null,
        thumbStatus : null,
        isLeader : false,
        isManager : false,
        redo: false,
        thumbId : null,
        currentImg : null,
        job: null,
        imageId : null,
        staffId: null,
        designerId: null,
    });
    const [thumbActive, setThumbActive] = useState(false);
    const [isAssignTask, setIsAssignTask] = useState(false);
    const [assignType, setAssignType] = useState('');
    const [userAssign, setUserAssign] = useState([]);
    const [userSelected, setUserSelected] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(Date.now());
    const [popUpImage, setPopUpImage] = useState(false);

    const userTeams = authUser.teams.filter(item => item.role>=0 ).map(item => item.teamId);
    const [filter, setFilter] = useState({});    
    const defaultFilter = {authUser:authUser.id, state:'Todo'};


    useEffect(() => {// remove all filters when user leaves the page
        return () => {
            localStorage.removeItem('RaStore.workspace/thumbnail.listParams');
        };
    },[])

    const ThumbFilter = () => {
        const isManager = authUser.teams.some(item => item.role === 3);
        const status = useMemo(() => [
            { id: 'Todo', name: 'Todo' },
            { id: 'Waiting', name: 'Waiting' },
            { id: 'Completed', name: 'Completed' }, 
        ], []);    
        return (
            <Filter >
                <SelectInput label="State" source="status" choices={status} alwaysOn onChange={filterWithStatus} emptyText="All"/>
                {isManager?<ReferenceInput  
                    source="staffId" 
                    reference="user" alwaysOn 
                    filter={{
                        AND: [
                            {taskTypes: {some: {teamId: { in: userTeams },taskTypeId: 8,active: true}}},
                            {taskTypes: {some: {teamId: { in: userTeams },taskTypeId: 1,active: true}}},
                        ],
                        active: true                            
                    }} 
                    sort={{ field: 'firstName', order: 'DESC' }}
                >
                    <SelectInput label="Creator" optionText="firstName" optionValue="id" emptyText="All"/>
                </ReferenceInput>:null}
                <ReferenceInput  
                    source="designerId" 
                    reference="user" alwaysOn 
                    filter={{
                        AND: [
                            {taskTypes: {some: {teamId: { in: userTeams },taskTypeId: 8,active: true}}},
                            {
                              OR: [
                                {taskTypes: {none: {teamId: { in: userTeams },taskTypeId: 1}}},
                                {taskTypes: {some: {teamId: { in: userTeams },taskTypeId: 1,active: false}}}
                              ]
                            }
                          ]
                    }}
                    sort={{ field: 'firstName', order: 'ASC' }}
                >
                    <SelectInput label="Designer" optionText="firstName" optionValue="id" emptyText="All"/>
                </ReferenceInput>
                <TextInput label="Title" source="title"  alwaysOn/>
            </Filter>
        );
    };

    const filterWithStatus = () => {
       setFilter({authUser:authUser.id});
    }

    const CreatorField = () => {
        return <ReferenceField source='staffId' reference="User" >
            <TextField source="firstName" />
        </ReferenceField> 
    }

    const DesignerField = () => {
        const record = useRecordContext();
        if(!record.designerId) return <span>Not Assigned</span>;
        return <ReferenceField source='designerId' reference="User" >
            <TextField source="firstName" />
        </ReferenceField> 
    }

    const ThumbRowStyle = (record, index) => {
        let isRedo = false;
        const isStaff =  record.designerId === authUser.id;
        const isCreator = record.staffId === authUser.id;
        const isLeader = (record.task.job.team.leaders.thumbnail.includes(authUser.id));
        const isManager = (record.task.job.team.managers.includes(authUser.id));
        if(!record.designerId && record.staffId === authUser.id && record.task.rush){  return { backgroundColor: '#c9a0dc' }}
        if(record.designerId=== authUser.id && record.task.rush){  return { backgroundColor: '#c9a0dc' }}

        if(record.thumbnail.some(item => (item.status.leader === -2 || item.status.creator === -2 || item.status.manager === -2))) {isRedo = true}    
        switch (record.status) {                      
            case 'Open':{
                return  ( record.staffId===authUser.id)?{ backgroundColor: '#f5f774' }: null;   
            }     
            case 'Working': return   isStaff? { backgroundColor: '#f5f774' }: null;          
            case 'Submitted':{
                return ((isRedo && isStaff )|| isLeader ? { backgroundColor: '#f5f774' } : { backgroundColor: 'orange' });  // inreview is organge
            }
            case 'LeaderApproved':{
                return ((isRedo && isStaff)|| isCreator ||(record.thumbnail.find((item) => (item.status.leader === 0)) && isLeader ))? { backgroundColor: '#f5f774' } : { backgroundColor: 'orange' };
            }
            case 'Approved':
            case 'CreatorApproved':{
                return ((isRedo && (isStaff || isCreator))|| isManager)?{ backgroundColor: '#f5f774' }: record.status==='CreatorApproved'? { backgroundColor: 'orange' } :  {backgroundColor: '#6df7a4' };   
            }            
            
            case 'Completed': return { backgroundColor: '#6df7a4' }; 
            default : return null;
        }
    };

    const ImageRenderer = ({ record}) => {
        if(record.thumbnail.length < 1) return (<span>Thumbnails...</span>);
        const isLeader =  (record.task.job.team.leaders.thumbnail.includes(authUser.id));
        const isManager =  (record.task.job.team.managers.includes(authUser.id));
        const isCreator = record.staffId0 === authUser.id;
        return (<>
            {record.thumbnail.map((item, index) =>{
                let color = "white";
                let isRedo = false;
                isRedo = Object.values(item.status).some(value => value <0 );
                if(isRedo){return null;}
                switch(record.status){
                    case 'Open':
                    case 'Working':  break;
                    case 'Submitted':{
                        if(item.status.leader === 1) color = '#6df7a4';
                        else if(isLeader ) color = '#f5f774'; //yellow
                        else color = 'white';
                        break;
                    }
                    case 'LeaderApproved':{
                        if(item.status.creator === 1) color = '#6df7a4';
                        else if( isCreator || (item.status.leader===0 && isLeader)) color = '#f5f774'; //yellow
                        else color = 'white';
                        break;
                    }
                    case 'CreatorApproved':
                    case 'Approved':
                    case 'Completed' :{
                        if(item.status.manager===1) color = '#6df7a4';
                        else if((isManager && item.status.manager ===0) || (item.status.creator===0 && isCreator) ) color = '#f5f774'; //yellow
                        else color = 'white';
                        break;
                    }
                    default: break;
                }
                return <img 
                    src={"https://drive.google.com/thumbnail?id="+item.id} 
                    alt="img from GG" height="72" 
                    key={index}
                    onClick={(e) => {setState({
                        ...state,
                        indexImage: index,
                        thumbId: record.id, 
                        imageId:item.id, 
                        job: record.task.job,
                        redo : isRedo,
                        currentImg : item,
                        thumbStatus: record.status,
                        isLeader : isLeader,
                        isManager : isManager,
                        staffId: record.staffId,
                        designerId: record.designerId
                    });
                    setPopUpImage(true)}}
                    style={{ border: `2px solid ${color}`, cursor: 'pointer' }}
                />
            }
            )}
        </>
        );
    };   

    const RedoImageRenderer = ({ record}) => {
        if(record.thumbnail.length < 1) return (<span>Thumbnails...</span>);
        const isLeader = (record.task.job.team.leaders.thumbnail.includes(authUser.id));
        const isManager = (record.task.job.team.managers.includes(authUser.id) );
        return (<>
            {record.thumbnail.map((item, index) =>{
                let color = '#ffc0cb';//pink
                if(item.status.leader === -1 || item.status.creator === -1 || item.status.manager === -1) color = '#f5f774';//yellow
                let isRedo = false;
                isRedo = Object.values(item.status).some(value => value < 0);
                if(!isRedo){return null}
                return <img 
                    src={"https://drive.google.com/thumbnail?id="+item.id} 
                    alt="img from GG" height="72" 
                    onClick={(e) => {setState({
                        ...state,
                        indexImage: index,
                        thumbId: record.id, 
                        imageId:item.id, 
                        job: record.task.job,
                        redo : true,
                        currentImg : item,
                        thumbStatus: record.status,
                        isLeader : isLeader,
                        isManager : isManager,
                        staffId: record.staffId,
                        designerId: record.designerId
                    });
                    setPopUpImage(true)}}
                    style={{ border: `2px solid ${color}`, cursor: 'pointer' }}
                />
            }
            )}
        </>
        );
    };

    const RejectImageRenderer = ({ record}) => {
        if(record.thumbnail.length < 1) return null;
        return (<>
            {record.rejectData.map((item, index) => 
                <a href={`https://drive.google.com/file/d/${item.id}/view`} target="_blank" rel="noreferrer" key={index}>
                    <img 
                        src={"https://drive.google.com/thumbnail?id="+item.id} 
                        alt="img from GG" height="72" 
                        style={{ border: `2px solid #a9a9a9` }}
                    />
                </a>
            )}
        </>
        );
    };

    const ActionOnImg = () => {
        if(!popUpImage) return null;
        const handleSelectNewPic = async () => {
            setPopUpImage(false);
            let token = await axios.get(`${process.env.REACT_APP_API_URL}api/token/googleToken?id=${authUser.id}`);
            openPicker({
                clientId : process.env.REACT_APP_GOOGLE_CLIENT_ID,
                developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
                viewId: "DOCS_IMAGES",
                token:  token.data,
                setIncludeFolders : true,
                supportDrives: true, 
                multiselect: false,
                setParentFolder : state.job.jobInfo.thumbnailsFolder,         
                cropping: true,
                setOrigin: process.env.REACT_APP_GOOGLE_ORIGIN,
                callbackFunction: async (data) => {
                    if (data.action === 'cancel') {
                        console.log('User clicked cancel/close button')
                    } else if (data.action === 'picked') {
                        if(!window.confirm('Are you sure you want to select this thumbnail?')) return;
                        setIsLoading(true); 
                        try{
                            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/thumbnail/replaceImg/${state.thumbId}`, {
                                imgId:data.docs[0].id, 
                                indexImage: state.indexImage
                            });  
                            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/thumbnail/publicImage`, data.docs)
                            .then(() => {
                                resetState();
                                refresh();
                                setIsLoading(false);                                
                            });
                        }catch(err){
                            console.log(err);
                        }
                    }
                }
            })
        } 
        let isRedo = (state.currentImg.status.leader ===-2 || state.currentImg.status.creator ===-2 || state.currentImg.status.manager ===-2)?true:false;
        switch(state.thumbStatus){
            case 'Open':
            case 'Working':{
                return <Button onClick={handleViewImgClick} color="success">View </Button>;
            }            
            case 'Submitted':{
                if(state.currentImg.status.leader === 1) return <Button onClick={handleViewImgClick} color="success">View </Button>;
                else if(state.currentImg.status.leader ===2 && state.designerId === authUser.id ) {
                    return <ButtonGroup>
                        <Button onClick={()=>handleImgClick('Fixed',null)} color="warning">Fixed </Button>
                        <Button onClick={handleViewImgClick} color="success">View </Button>;
                        <Button onClick={()=>window.open(`https://drive.google.com/drive/folders/${state.job.jobInfo.thumbnailsFolder}` ,"_blank")} color="primary">Upload </Button>;
                        <Button onClick={handleSelectNewPic} color="warning">Another File </Button>;
                    </ButtonGroup>
                } 
                else if(state.currentImg.status.leader ===-2 && state.designerId !== authUser.id) return <Button onClick={handleViewImgClick} color="success">View </Button>;
                else if (state.isLeader) {
                    return <ButtonGroup>
                        <Button onClick={()=>handleImgClick(state.isManager?'Approved':'LeaderApproved',state.isManager?'manager':'leader')} color="success">Approve </Button>
                        <Button onClick={()=>handleImgClick('Reject','leader')} color="warning">Reject </Button>
                        <Button onClick={()=>handleImgClick('Redo','leader')} color="primary">Redo </Button>
                    </ButtonGroup>;
                }
                  
                else return <Button onClick={handleViewImgClick} color="success">View </Button>;
            }
            case 'LeaderApproved':{
                if(state.currentImg.status.creator === 1) return <Button onClick={handleViewImgClick} color="success">View </Button>;                
                else if(isRedo && state.designerId === authUser.id) {
                    return <ButtonGroup>
                        <Button onClick={()=>handleImgClick('Fixed',null)} color="warning">Fixed </Button>
                        <Button onClick={handleViewImgClick} color="success">View </Button>;
                        <Button onClick={()=>window.open(`https://drive.google.com/drive/folders/${state.job.jobInfo.thumbnailsFolder}` ,"_blank")} color="primary">Upload </Button>;
                        <Button onClick={handleSelectNewPic} color="warning">Another File </Button>;
                    </ButtonGroup>
                }
                else if(state.currentImg.status.leader === -1 && state.isLeader) {
                    return <ButtonGroup>
                        <Button onClick={()=>handleImgClick(state.isManager?'Approved':'LeaderApproved',state.isManager?'manager':'leader')} color="success">Approve </Button>
                        <Button onClick={()=>handleImgClick('Reject','leader')} color="warning">Reject </Button>
                        <Button onClick={()=>handleImgClick('Redo','leader')} color="primary">Redo </Button>
                    </ButtonGroup>;
                } 
                else if (state.staffId === authUser.id && (state.currentImg.status.creator === 0 ||state.currentImg.status.creator === -1) ) {
                    return <ButtonGroup>
                        <Button onClick={()=>handleImgClick(state.isManager?'Approved':'CreatorApproved',state.isManager?'manager':'creator')} color="success">Approve </Button>
                        <Button onClick={()=>handleImgClick('Reject','creator')} color="warning">Reject </Button>
                        <Button onClick={()=>handleImgClick('Redo','creator')} color="primary">Redo </Button>
                    </ButtonGroup>;
                }                                
                else return <Button onClick={handleViewImgClick} color="success">View </Button>;
            }
            case 'CreatorApproved':
            case 'Approved':
            case 'Completed' :{
                if(state.currentImg.status.manager === 1) return <Button onClick={handleViewImgClick} color="success">View </Button>;
                else if(isRedo && ( state.designerId === authUser.id || (state.staffId === authUser.id && state.designerId))) {
                    return <ButtonGroup>
                        <Button onClick={()=>handleImgClick('Fixed',null)} color="warning">Fixed </Button>
                        <Button onClick={handleViewImgClick} color="success">View </Button>;
                        <Button onClick={()=>window.open(`https://drive.google.com/drive/folders/${state.job.jobInfo.thumbnailsFolder}` ,"_blank")} color="primary">Upload </Button>;
                        <Button onClick={handleSelectNewPic} color="warning">Another File </Button>;
                    </ButtonGroup>
                }
                else if (state.isManager && (state.currentImg.status.manager === 0 || state.currentImg.status.manager === -1)) {
                    return <ButtonGroup>
                        <Button onClick={()=>handleImgClick("Approved","manager")} color="success">Approve </Button>
                        <Button onClick={()=>handleImgClick('Reject',"manager")} color="warning">Reject </Button>
                        <Button onClick={()=>handleImgClick('Redo',"manager")} color="primary">Redo </Button>
                    </ButtonGroup>;
                }
                else return <Button onClick={handleViewImgClick} color="success">View </Button>;
            }
            default:{
                return <Button onClick={handleViewImgClick} color="success">View </Button>
            }
        }
    };

    const ActionRenderer = ({record}) => {
        const [isCompleted, setIsCompleted] = useState(false);
        const [insertImg, setInsertImg] = useState(false);
        const [approved, setApproved] = useState(false);
        const [creatorApproved, setCreatorApproved] = useState(false);
        const [leaderApproved, setLeaderApproved] = useState(false);
        const isLeader = (record.task.job.team.leaders.thumbnail.includes(authUser.id));
        const isManager = (record.task.job.team.managers.includes(authUser.id) );
        useEffect(() => {
            if(record.thumbnail.find(item => (item.status.leader === -2 || item.status.creator === -2 || item.status.manager === -2))) setInsertImg(true);
            if(record.thumbnail.every(item => item.status.leader !==0) && record.thumbnail.some(item => item.status.leader === 1 )) setLeaderApproved(true);
            if(record.thumbnail.every(item => item.status.creator !==0) && record.thumbnail.some(item => item.status.creator === 1 )) setCreatorApproved(true);
            if(record.thumbnail.every(item => item.status.manager !==0) && record.thumbnail.some(item => item.status.manager === 1 )) setApproved(true);
            if(record.thumbnail.every(item => item.status.manager === 1)) setIsCompleted(true);
        }, [record]);       
        
        switch(record.status){
            case 'Completed' : return <span>Completed</span>;

            case 'Approved':{
                let findRedoFixed = record.thumbnail.find(item => (item.redo && item.redo[0].userRedo === authUser.id && item.status.manager===-1));

                if(isManager && isCompleted){
                    return <Button variant="contained" color="warning" onClick={() => handleThumbUpdate(record.id,"Completed")}>Completed</Button>
                }
                else if(record.designerId===authUser.id && insertImg) 
                    return <Button variant="contained" color="primary" onClick={() =>handleThumbInsert(record)}>Insert Thumb </Button>
                else if(findRedoFixed) return <span>Plesae Check Image Redo</span>
                else return <span>Approved</span>
            }
            
            case 'CreatorApproved':{
                let findRedoFixed = record.thumbnail.find(item => (item.redo && item.redo[0].userRedo === authUser.id && item.status.manager===-1));                
                if(isManager){
                    if(approved) return <Button variant="contained" color="primary" onClick={() => handleThumbUpdate(record.id,"Approved")}>Approve</Button>;
                    else return <span>Please Check All Images</span>
                } 
                else if(
                    ( record.designerId===authUser.id && insertImg)|| //  by staff
                    (record.staffId === authUser.id && insertImg && record.designerId) //by creator
                ) return <Button variant="contained" color="primary" onClick={() =>handleThumbInsert(record)}>Insert Thumb </Button>

                else if(findRedoFixed) return <span>Plesae Check Image Redo</span>
                else return <span>In Review</span>
            }

            case 'LeaderApproved':{
                let findRedoFixed = record.thumbnail.find(item => (item.redo && item.redo[0].userRedo === authUser.id && item.status.creator===-1));  
                if(authUser.id === record.staffId){
                    if(creatorApproved) return <Button variant="contained" color="primary" onClick={() => handleThumbUpdate(record.id,isManager?"Approved":"CreatorApproved")}>Approve</Button>;
                    else return <span>Please Check All Images</span>
                }
                else if(record.designerId===authUser.id && insertImg) return <Button variant="contained" color="primary" onClick={() =>handleThumbInsert(record)}>Insert Thumb </Button>
                else if(findRedoFixed) return <span>Plesae Check Image Redo</span>
                else return <span>In Review</span>
            }

            case 'Submitted':{
                if(isLeader){
                    if(leaderApproved) return <Button variant="contained" color="primary" onClick={() => handleThumbUpdate(record.id, isManager?"Approved":"LeaderApproved")}>Approve</Button>;
                    else return <span>Please Check All Images</span>
                }
                else if(authUser.id === record.designerId && insertImg) return <Button variant="contained" color="primary" onClick={() =>handleThumbInsert(record)}>Insert Thumb </Button>;
                else return <span>In Review</span>
            }

            case 'Working':{
                if(authUser.id ===  record.designerId){
                    return( 
                    <ButtonGroup> 
                        <Button variant="contained" color="primary" onClick={()=>window.open(`https://drive.google.com/drive/folders/${record.task.job.jobInfo.thumbnailsFolder}` ,"_blank")}>Upload</Button> 
                        <Button variant="contained" color="primary" onClick={() =>handleSelectThumb(record)}>Select Thumb </Button>
                        {record.thumbnail.length>0 &&  <Button variant="contained" color="primary" onClick={() =>handleThumbUpdate(record.id, isLeader? 'LeaderApproved':"Submitted")}>Submit</Button>}
                    </ButtonGroup> )
                }
                else return <span>Waiting for Submission</span>
            }

            default:{
                if(authUser.id ===  record.staffId){
                    return(
                        <ButtonGroup>            
                            <Button variant="contained" color="info" onClick={()=>window.open(`https://drive.google.com/drive/folders/${record.task.job.jobInfo.thumbnailsFolder}` ,"_blank")}>Upload</Button>
                            <Button variant="contained" color="primary" onClick={() =>handleSelectThumb(record)}>Select Thumb </Button>
                            <Button variant="contained" color="primary" onClick={() =>handleAssignTask(record)}>Assign Task </Button>
                            {record.thumbnail.length>0 && <Button variant="contained" color="primary" onClick={() =>
                                handleThumbUpdate(
                                    record.id,
                                    isManager?"Completed":isLeader? "LeaderApproved":"CreatorApproved"
                                    )}
                            >Submit</Button>}
                        </ButtonGroup>
                    )
                }
                else return <span>Waiting for Submission</span>
            }
        }
    }

    const resetState = () => {
        setState({
            ...state,
            thumbStatus: null,
            indexImage : null,
            thumbId : null,
            currentImg : null,
            job: null,
            redo : false,
            imageId : null,
            isLeader : false,
            isManager : false,
            staffId: null,
            designerId: null
        });
    }

    const handleSelectThumb = async (record) => {
        let imgStatus = record.designerId? {leader:0,creator:0,manager:0}:{manager:0};
        let token = await axios.get(`${process.env.REACT_APP_API_URL}api/token/googleToken?id=${authUser.id}`);
        openPicker({
            clientId : process.env.REACT_APP_GOOGLE_CLIENT_ID,
            developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
            viewId: "DOCS_IMAGES",
            token:  token.data,
            setIncludeFolders : true,
            supportDrives: true, 
            multiselect: true,
            setParentFolder : record.task.job.jobInfo.thumbnailsFolder,         
            cropping: true,
            setOrigin: process.env.REACT_APP_GOOGLE_ORIGIN,
            callbackFunction: async (data) => {
                if (data.action === 'cancel') {
                    console.log('User clicked cancel/close button')
                } else if (data.action === 'picked') {
                    if(!window.confirm('Are you sure you want to select this thumbnail?')) return;
                    setIsLoading(true); 
                    try{
                        let thumbIds = [];
                        data.docs.map(async (item) => {thumbIds.push({id:item.id, status: imgStatus})});
                        await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/thumbnail/${record.id}`,  {thumbnail: thumbIds});  
                        await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/thumbnail/publicImage`, data.docs)
                        .then(() => {
                            refresh();
                            setIsLoading(false);
                        });
                    }
                    catch(err){
                        console.log(err);
                    }
                }
            }
        })
    }

    const handleThumbInsert = async (record) => {
        let imgStatus = record.designerId ? {leader:0}:{manager:0};
        let token = await axios.get(`${process.env.REACT_APP_API_URL}api/token/googleToken?id=${authUser.id}`);
        openPicker({
            clientId : process.env.REACT_APP_GOOGLE_CLIENT_ID,
            developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
            viewId: "DOCS_IMAGES",
            token:  token.data,
            setIncludeFolders : true,
            supportDrives: true, 
            multiselect: true,
            setParentFolder : record.task.job.jobInfo.thumbnailsFolder,         
            cropping: true,
            setOrigin: process.env.REACT_APP_GOOGLE_ORIGIN,
            callbackFunction: async (data) => {
                if (data.action === 'cancel') {
                    console.log('User clicked cancel/close button')
                } else if (data.action === 'picked') {
                    if(!window.confirm('Are you sure you want to select this thumbnail?')) return;
                    setIsLoading(true); 
                    try{
                        let thumbIds = [];
                        data.docs.map(async (item) => {thumbIds.push({id:item.id, status: imgStatus})});
                        await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/thumbnail/insert/${record.id}`,  {thumbIds});  
                        await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/thumbnail/publicImage`, data.docs)
                        .then(() => {
                            refresh();
                            setIsLoading(false);
                        });
                    }
                    catch(err){
                        console.log(err);
                    }
                }
            }
        })
    } 

    const handleThumbUpdate = async (id, status) => {
        let title = 'Approve';
        if(status === 'Submitted') {title = 'Submit'};
        if (!window.confirm('Are you sure you want to '+ title +' this Thumbnail?')) return;
        setIsLoading(true); 
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/thumbnail/${id}`,  {status: status, user: authUser.id})
            .then(() => {
                refresh();
                setIsLoading(false);
            });
        }
        catch(err){
            console.log(err);
        }
    }

    const handleImgClick = async (type,userType) => {
        if(type === 'Approved' && !window.confirm('Are you sure you want to approve this image?')) return;
        let reason = '1';
        if(type === 'Reject' || type==='Redo'){
            reason = prompt('Please enter the reason');
            if(!reason) return;
        }
        setIsLoading(true); 
        try{
            setPopUpImage(false);
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/thumbnail/updateThumbImg/${state.thumbId}`, {
                type: type,
                userType: userType,
                indexImage: state.indexImage,
                imageId : state.imageId,
                reason :reason,
                user : authUser.id,
            }).then(() => {
                refresh();
                resetState();                
                setIsLoading(false);       
                setLastUpdated(Date.now());      
            });
        } catch(err){
            console.log(err);
        }  
    }

    const handleViewImgClick = () => {
        window.open("https://drive.google.com/file/d/"+state.imageId+"/view","_blank");
        setPopUpImage(false);
    }

    const handleAssignTask = async (record) => {
        setThumbActive(record);
        setIsAssignTask(true);        
    };

    const changeAssignTaskType = async (e) => {
        setAssignType(e.target.value);
        const endpoint = e.target.value === 'team'? 'api/user/findLeader': 'api/user/findStaff';
        const users = await axios.get(`${process.env.REACT_APP_API_URL}${endpoint}?type=thumbnail&team=${thumbActive.task.job.teamId}`);
        setUserAssign(users.data);
    }

    const handleSubmitAssignTask = async () => {
        if (!window.confirm('Are you sure you want to assign this task?')) return;    
        setIsLoading(true);
        handleCloseAssignTask();
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/thumbnail/assignTask`, { 
                type: assignType,
                user: userSelected, 
                thumbId : thumbActive.id,
                designerId: userSelected.id
            });            
        }
        catch(err){
            console.log(err);
        } finally { 
            refresh();
            setIsLoading(false);
        }   
    }

    const handleCloseAssignTask = () => {
        setAssignType('');
        setThumbActive(null);
        setIsAssignTask(false);
        setUserSelected(null);
        setUserAssign([]);
    }   


    return (
        <List {...props} filter={filter} filterDefaultValues={defaultFilter}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
            <ThumbFilter />
            <Dialog //Dialog for Assign Task
                open={isAssignTask}
                onClose={handleCloseAssignTask}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Assign Task:</DialogTitle>
                <DialogContent >
                    <FormControl fullWidth>
                        <InputLabel id="assign-type-label">Assign Type</InputLabel>
                        <Select
                            labelId="assign-type-label"
                            value={assignType}
                            onChange={changeAssignTaskType}
                        >
                            <MenuItem value=""><em>Select Assign Type</em></MenuItem>
                            <MenuItem value="team">Assign for Team</MenuItem>
                            <MenuItem value="user">Assign for User</MenuItem>
                        </Select>
                    </FormControl>
                    {userAssign.length > 0 && (
                        <FormControl fullWidth style={{ marginTop: '1rem' }}>
                            <InputLabel id="user-assign-label">{assignType==='team'? 'Select Team':'Select User'}</InputLabel>
                            <Select
                                labelId="user-assign-label"
                                value={userSelected?.id || ""}
                                onChange={(e) => setUserSelected({ id: parseInt(e.target.value) })}
                            >
                                {userAssign.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.firstName}{assignType==='team'?`'s Team`:''}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAssignTask} color="primary">Cancel</Button>
                    {userSelected && <Button onClick={handleSubmitAssignTask} color="primary">Submit</Button>}
                </DialogActions>
            </Dialog>
            <Dialog  // Pop up image
                open={popUpImage}
                onClose={()=>{setPopUpImage(false); resetState();}}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Image Status: {state.redo? 'Need Redo': state.thumbStatus}</DialogTitle>
                <DialogContent>
                    <a href={`https://drive.google.com/file/d/${state.imageId}/view`} target="_blank" rel="noreferrer">
                        <img 
                            src={"https://drive.google.com/thumbnail?id="+state.imageId} 
                            alt="img from GG" height="220" 
                        />
                    </a>
                </DialogContent>
                <DialogActions>
                    <ActionOnImg />
                </DialogActions>
            </Dialog>
            <Datagrid rowStyle={ThumbRowStyle} bulkActionButtons={false}>                
                <FunctionField label="Title" render={record =>record.task.job.name} />  
                <FunctionField label="" render={record =>{
                    return <a href={`/#/job/${record.task.job.id}/show`} target="_blank" rel="noreferrer">View Project</a>
                
                }} />     
                <FunctionField label="Thumbnails" render={(record) => (
                    <ImageRenderer record={record} />
                )}/>
                <FunctionField label="Redo Thumbnails" render={(record)=>(
                    <RedoImageRenderer record={record} />
                )} />
                <FunctionField label="Rejected Thumbnails" render={(record) => (
                    <RejectImageRenderer record={record} />
                )}/>
                <CreatorField label="Creator" source="staffId" sortable />
                <DesignerField label="Designer"  source="designerId" sortable/>
                <FunctionField label="Actions" render={(record) => ( <ActionRenderer key={lastUpdated} record={record} /> ) }/>
                <FunctionField label="Rush" render={(record) => <CustomRush record={record} setIsLoading={setIsLoading} userTeams={userTeams} />} />
            </Datagrid>
        </List>
    )
    };

export default Thumb;