import React, {useContext, useState, useMemo, useEffect} from "react";
import {     
    List,
    Datagrid,
    TextField,
    ReferenceField,
    FunctionField,
    Filter,
    SelectInput,
    useRefresh,
    useRecordContext,
    useListContext
} from 'react-admin';
import { Link } from 'react-router-dom';
import {isWeekend, addDays } from 'date-fns';
import DateFieldWithTimezone from "../utils/DateFieldWithTimezone";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { UserContext } from "../auth/UserContext";
import axios from "../axiosConfig";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const ListTask = (props) =>{
    const { selectedIds } = useListContext();
    const hasSelection = selectedIds && selectedIds.length > 0;
    const authUser = useContext(UserContext);
    const userTeams = authUser.teams? authUser.teams.map(team => team.teamId): [];
    const [filter, setFilter] = useState(null);    
    const refresh = useRefresh();
    const [isLoading, setIsLoading] = useState(false);
    const [staffsSelect, setStaffsSelect] = useState([]);
    const [staffSelected, setStaffSelected] = useState(null);
    const [isSelectUserPopup, setIsSelectUserPopup] = useState(false);
    const [taskActive, setTaskActive] = useState();
    const taskTypes = useMemo(() =>["idea","outline","draff","script","film","edit","picture","thumbnail"],[]);  

    useEffect(() => {
        setFilter({
            OR: [
                { staffId: authUser.id },
                {AND:[
                    {childTaskId: 0},
                    {type :6},
                    {job: {team:{ leaders: { path: ['edit'], array_contains: authUser.id } }}}
                ]},
                {AND:[
                    {childTaskId: 0},
                    {type :8},
                    {job: {team:{ leaders: { path: ['thumbnail'], array_contains: authUser.id } }}}
                ]},
                {AND:[{childTaskId: 0},{job: {team: { managers: { has: authUser.id } }}}]}
            ]
        });
    }, [authUser]); 

    const TaskRowStyle = (record) => {
        if(record.rush) return { backgroundColor: '#c9a0dc'}; //light purple
        switch (record.status) {                      
            // case 'Waiting': { // for manager select team edit
            //     return (record.job.team.managers.includes(authUser.id))? { backgroundColor: '#f5f774' }: null;              
            // }
            case 'Pending': {// for leader select staff edit
                return (record.job.team.leaders['edit'].includes(authUser.id) || record.job.team.leaders['thumbnail'].includes(authUser.id) )?{ backgroundColor: '#f5f774' }: null;   
            }
            case 'Open':{
                return  ( record.staffId===authUser.id)?{ backgroundColor: '#f5f774' }: null;   
            }            
            case 'Submitted':{
                if(record.type===6 || record.type === 8) {
                    return (record.job.team.leaders['edit'].includes(authUser.id)||  record.job.team.leaders['thumbnail'].includes(authUser.id))?{ backgroundColor: '#f5f774' }: null;   
                }
                else{
                    return (record.job.team.managers.includes(authUser.id))?{ backgroundColor: '#f5f774' }: null;   
                }
            }
            case 'LeaderApproved':{
                return (record.staffId===authUser.id)?{ backgroundColor: '#f5f774' }: null;   
            }
            case 'CreatorApproved':{
                return (record.job.team.managers.includes(authUser.id))?{ backgroundColor: '#f5f774' }: null;   
            }
            case 'Working': return null;
            case 'Completed': return { backgroundColor: '#6df7a4' }; 

            default: return null;
        }
    };

    const TaskFilter = () => {
        const status = useMemo(() => [
            { id: 'To Do', name: 'To Do' }, 
            { id: 'Waiting ', name: 'Waiting' },
            { id: 'Available  ', name: 'Available' },
            { id: 'Completed', name: 'Completed' },
        ], []);
        return (
            <Filter>
                <SelectInput 
                    label="Status" 
                    source="status" 
                    choices={status} 
                    alwaysOn 
                    onChange={(e)=>filterWithStatus(e.target.value) }
                />
            </Filter>
        );
    };
    
    const filterWithStatus = (status) => {
        if(status === 'Working'){
            setFilter(
                {OR: [
                    { staffId: authUser.id },
                    {AND:[
                        {childTaskId: 0},
                        {type :6},
                        {job: {team:{ leaders: { path: ['edit'], array_contains: authUser.id } }}}
                    ]},
                    {AND:[
                        {childTaskId: 0},
                        {type :8},
                        {job: {team:{ leaders: { path: ['thumbnail'], array_contains: authUser.id } }}}
                    ]},
                    {AND:[{childTaskId: 0},{job: {team: { managers: { has: authUser.id } }}}]}
                ]}, 
            );
        } else if(status === 'Completed'){
            setFilter(
                {OR: [
                    { staffId: authUser.id },
                    {AND:[
                        {parent: null},
                        {type :6},
                        {job: {team:{ leaders: { path: ['edit'], array_contains: authUser.id } }}}
                    ]},
                    {AND:[
                        {parent: null},
                        {type :8},
                        {job: {team:{ leaders: { path: ['thumbnail'], array_contains: authUser.id } }}}
                    ]},
                    {AND:[{parent: null},{job: {team: { managers: { has: authUser.id } }}}]}
                ]},               
            );
        } else{
            setFilter({
                OR: [
                    { staffId: authUser.id },
                    {AND:[
                        {childTaskId: 0},
                        {type :6},
                        {job: {team:{ leaders: { path: ['edit'], array_contains: authUser.id } }}}
                    ]},
                    {AND:[
                        {childTaskId: 0},
                        {type :8},
                        {job: {team:{ leaders: { path: ['thumbnail'], array_contains: authUser.id } }}}
                    ]},
                    {AND:[{childTaskId: 0},{job: {team: { managers: { has: authUser.id } }}}]}
                ]
            });
        }

    }
    
    const adjustDays = (date, daysToAdjust) => {
        let result = new Date(date);
        let daysAdjusted = 0;        
        while (daysAdjusted < Math.abs(daysToAdjust)) {
            result = addDays(result, daysToAdjust > 0 ? 1 : -1); 
            if (!isWeekend(result)) {daysAdjusted++;}
        }        
        return result;
    }

    const DueDaysField = ({ source }) =>{
        const record = useRecordContext();
        if(record.status === 'Completed') return <span>Completed</span>;
        // if(record.type === 1||record.type===5) return null;
        const startAt = new Date(record.startAt);
        const dueDays = record.job.team.info["dueDays"][taskTypes[record.type-1]];

        const startDate = typeof startAt === 'string' ? new Date(startAt) : startAt;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const dueDate = adjustDays(startDate, dueDays);
        const daysLeft = Math.ceil((dueDate - today) / (1000 * 60 * 60 * 24));
        if(daysLeft<0) {
            const style = { color: 'red' };
            return <strong style ={style}>Past Due {Math.abs(daysLeft)} days</strong>;
        } else if (daysLeft <= 2) {
            const style = { color: 'blue' };
            return <strong style={style} >Due in {daysLeft} days</strong>;
        } else {
            return `Due in ${daysLeft} days`;
        }
    }
      
    //for Dialog
    const handleCloseDialog = () => {
        setIsSelectUserPopup(false);
        setStaffSelected(null);
        setStaffsSelect([])
    }

    const handleOpenDialog = async (type, task) => {        
        setTaskActive(task);
        setIsLoading(true);
        try{
            const taskType = task.type === 6? "edit" : "thumbnail";
            const users = await axios.get(`api/user/findStaff?type=${taskType}&team=${task.job.teamId}`);
            setStaffsSelect(users.data);

        } catch(err){
            console.log(err);
        } finally{
            setIsLoading(false);
            setIsSelectUserPopup(true);
        }
    }

    const handleSubmitStaff = async () => {
        if(!staffSelected){alert("Please select user");return }
        setIsSelectUserPopup(false);
        setIsLoading(true);
        try{
            let statusDetail = {}
            statusDetail = {Staff: staffSelected.id}
            const data = {
                taskId:  taskActive.id,
                staffId: staffSelected.id,
                statusDetail:statusDetail
            }
            await axios.put(`${process.env.REACT_APP_API_URL}api/task/setUserTask`,data); 
            refresh();
        } catch(err){
            console.log(err);
        }finally{
            setIsLoading(false);
            handleCloseDialog();
        }
    }  


    const ActionsField = ({record}) => {
        if(authUser.id!==record.staffId && record.childTaskId) return <span>Staff Working</span>;
        switch (record.status) {                      
        //     case 'Waiting': { // for manager select team edit
        //         return (record.job.team.managers.includes(authUser.id))? 
        //             <Button variant="contained" color="primary" onClick={() => handleOpenDialog("team",record)}>Select Team</Button> 
        //             : <span>Pending</span>;              
        //     }
            case 'Pending': {// for leader select staff edit
                return (record.job.team.leaders['edit'].includes(authUser.id)|| record.job.team.leaders['thumbnail'].includes(authUser.id))?
                    <Button disabled={hasSelection} variant="contained" color="primary" onClick={() => handleOpenDialog("staff",record)}>Select User</Button> 
                    : <span>Pending</span>;
            }
            case 'Open':{
                return  ( record.staffId===authUser.id)?
                    <Button component={Link} to={`../../workspace/${taskTypes[record.type-1]}`} variant="contained" color="primary">Goto Work</Button>
                    :<span>Staff Working</span>;
            }
            case 'Working':{
                return <span>Staff Working</span>;
            }
            case 'Submitted':{
                if(record.type===6 || record.type === 8) {
                    return (record.job.team.leaders['edit'].includes(authUser.id) || record.job.team.leaders['thumbnail'].includes(authUser.id) )?
                        <Button component={Link} to={`../../workspace/${taskTypes[record.type-1]}`} variant="contained" color="primary">Go Check</Button>
                        :<span>Waiting for Approval</span>;
                }
                else{
                    return (record.job.team.managers.includes(authUser.id))?
                        <Button component={Link} to={`../../workspace/${taskTypes[record.type-1]}`} variant="contained" color="primary">Go Check</Button>
                        :<span>Waiting for Approval</span>;
                }
            }
            case 'LeaderApproved':{
                return (record.staffId===authUser.id)?
                    <Button component={Link} to={`../../workspace/${taskTypes[record.type-1]}`} variant="contained" color="primary">Go Check</Button>
                    :<span>Waiting for Approval</span>;
            }
            case 'CreatorApproved':{
                return (record.job.team.managers.includes(authUser.id))?
                    <Button component={Link} to={`../../workspace/${taskTypes[record.type-1]}`} variant="contained" color="primary">Go Check</Button>
                    :<span>Waiting for Approval</span>;
            }
            case 'Completed': return <span>Completed</span>;
            default: return <span>Pending</span>;
        }
    } 

    const handleRushTask = async(task) => {
        let confirm = window.confirm("Are you sure to rush this task?");
        if(!confirm) return;
        try{
            setIsLoading(true);
            const data = {rush:true, statusDetail:{push:{userRush:authUser.id}}};
            await axios.put(`${process.env.REACT_APP_API_URL}api/task/${task.id}`,data); 
            if(task.childTaskId){
                await axios.put(`${process.env.REACT_APP_API_URL}api/task/${task.childTaskId}`,data);
            }
            
        } catch(err){
            console.log(err);
        }finally{
            refresh();
            setIsLoading(false);
        }
    }

    const RushField = ({record , userTeams}) => {
        if(record.status === 'Completed') return null;
        if(record.rush){
            if(record.childTaskId === 0 && authUser.id === record.staffId) return <strong>Need to Work Now</strong>;
            return null;            
        }
        // if rush is not active
        if(authUser.id===record.staffId){
            if(record.childTaskId) return <Button onClick={() =>handleRushTask(record)} color="primary">Rush</Button>;
            return null
        }
        const indexInTeam = userTeams.indexOf(record.job.teamId);
        if(authUser.teams[indexInTeam] && authUser.teams[indexInTeam].role > 0 && !record.rush) return <Button onClick={() =>handleRushTask(record)} color="primary">Rush</Button>;
        return null        
    }

    return (
        <List {...props}  filter={filter} sort={{ field: 'id', order: 'DESC' }} >
            <TaskFilter/>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
            <Dialog
                open={isSelectUserPopup}
                onClose={handleCloseDialog}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Select User:</DialogTitle>
                <DialogContent >
                    <select onChange={(e)=>setStaffSelected({id: parseInt(e.target.value), role: parseInt(e.target.options[e.target.selectedIndex].getAttribute('data-role'))})} >
                        <option value="">Select User</option>
                        {staffsSelect.map(user => {
                            return <option key={user.id} value={user.id} data-role = {user.teams? user.teams[0].role : 0} >{user.firstName} {user.lastName}</option>
                        })}
                    </select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
                    <Button onClick={()=>handleSubmitStaff()} color="primary">Save</Button>
                </DialogActions>
            </Dialog>
            <Datagrid bulkActionButtons={false} rowStyle={TaskRowStyle}>
                <ReferenceField source="jobId" reference="job" label="Title">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="type" reference="tasktype" label="Phase">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="staffId" reference="User">
                    <FunctionField label="User" render={(record) => `${record.firstName} ${record.lastName}`}  />
                </ReferenceField>    
                <DateFieldWithTimezone source="startAt" />
                <FunctionField label="Status" render={(record) =>{
                    if(record.status === 'Completed') return <span>Completed</span>;
                    else return <span>In Progress</span>;
                }}/>
                <DueDaysField label="Due" source="due" sortable={false} />             
                <FunctionField label="Actions" render={(record) => (<ActionsField record={record}/>)}/>
                <FunctionField render={(record) => (<RushField record={record} userTeams={userTeams} />)}/>
            </Datagrid>
        </List>
    )
};

export default ListTask