import React, {useEffect, useContext, useState, useMemo } from "react";
import { 
    List, 
    Datagrid, 
    TextField, 
    ReferenceField, 
    ReferenceInput,
    FunctionField, 
    Link,
    useRefresh, 
    Filter,
    TextInput,
    SelectInput,
    useRecordContext
} from 'react-admin';
import { UserContext } from "../auth/UserContext";
import axios from "../axiosConfig";
import {IdeaRowStyle} from "../utils/CustomRowStyle";
import { CustomRush } from "../utils/CustomField";
import ImageRenderer from "../utils/ImageRenderer";
import { stringify } from 'query-string';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
    Button,
    CircularProgress,
    Backdrop
}from '@mui/material';


const OutLine = (props) => {
    const refresh = useRefresh();
    const authUser = useContext(UserContext);
    
    const [isLoading, setIsLoading] = useState(false); 
    
    const [task, setTask] = useState([]);
    const [choiseTask, setChoiseTask] = useState(false);
    const [currentOutline, setCurrentOutline] = useState(false);
    const [isSelectTask, setIsSelectTask] = useState(false);
    const isManager = authUser.teams.some(item => item.role === 3);
    const userTeams = authUser.teams.filter(item => item.role>=0 ).map(item => item.teamId);
    const [filter, setFilter] = useState({});
    const defaultFilter = isManager?{ task:{job: {team: { managers: { has: authUser.id } }}}, status:'Todo'}:{staffId:authUser.id, status:'Todo'};

    useEffect(() => {// remove all filters when user leaves the page
        return () => {
            localStorage.removeItem('RaStore.workspace/outline.listParams');
        };
    },[])
    
    const OutLineFilter = () => {
        const status = useMemo(() => [
            { id: 'Todo', name: 'Todo' },
            { id: 'Completed', name: 'Completed' }            
        ], []);    
        return (
            <Filter >
                <SelectInput label="State" source="status" choices={status} alwaysOn onChange={filterWithStatus} emptyText="All"/>
                {isManager? <ReferenceInput  source="staffId" reference="user" alwaysOn filter={{taskTypes:{some:{taskTypeId:2, active:true, teamId:{in:userTeams}}} ,active:true}}  sort={{ field: 'firstName', order: 'ASC' }}>
                    <SelectInput label="Creator" optionText="firstName" optionValue="id" onChange={filterWithStatus} emptyText="All"/>
                </ReferenceInput>:null}
                <TextInput label="Title" source="title" onChange={filterWithStatus} alwaysOn/>
            </Filter>
        );
    };

    const TitleField = () => {
        const record = useRecordContext();
        return(
        <Link target="_blank" to={`https://docs.google.com/document/d/${record.googleId}/edit`} title="click to open">
            {record.task.job.name}
        </Link>)

    }

    const filterWithStatus = () => {
        if(isManager){setFilter({ task:{job: {team: { managers: { has: authUser.id } }}}})}
        else{setFilter( {task:{job:{teamId : {  in: userTeams } }}} )}
    }

    const handleJoinJob= async (record) => {
        setCurrentOutline(record);
        const query = {filter: JSON.stringify({ status: "Open", type: 2, staffId:record.staffId })};
        try {
            setIsLoading(true); 
            const choise = [];
            const response = await axios.get(process.env.REACT_APP_API_URL+'api/task/joinJob?'+ stringify(query));
            response.data.map((item,index) => (
                choise.push({id:item.id,name:`${item.job.name} `})
            ));
            setChoiseTask(choise);
            setIsSelectTask(true);
        } catch (error) {
            console.log(error);
        } finally {
            await refresh(); 
            setIsLoading(false);
        }
    };

    const JoinJob = async () => {
        if(!task) return;
        setIsLoading(true);
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/outline/joinJob/${currentOutline.id}`,  {taskId: task});
            setIsSelectTask(false);
        }
        catch(err){
            console.log(err);
        } finally {
            setTask(false);
            setCurrentOutline(false);
            refresh();
            setIsLoading(false);
        }
    }

    const handleSubmit = async (id) => {  
        if(!window.confirm(`Are you sure you want to Submit this Outline?`)) return;
        setIsLoading(true);    
        try{
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/outline/${id}`,  {status: "Completed"});     
        }
        catch(err){
            console.log(err);
        } finally {
            refresh();
            setIsLoading(false);
        }
    };

    return (
        <List {...props} filter={filter} sort={{ field: 'status', order: 'ASC' }} filterDefaultValues={defaultFilter} >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
            <OutLineFilter />
            <Dialog
                open={isSelectTask}
                onClose={()=>setIsSelectTask(false)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Select Task</DialogTitle>
                <DialogContent>
                    <span>Task:   </span>
                    <select onChange={(e) => setTask(parseInt(e.target.value))}>
                        <option value="0">Select Task</option> {choiseTask && choiseTask.map((item,index) => (<option value={item.id}>{item.name}</option>))} 
                    </select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>JoinJob()} color="primary">Ok </Button>
                    <Button onClick={()=>setIsSelectTask(false)} color="primary">Cancel </Button>
                </DialogActions>
            </Dialog>
            <Datagrid rowStyle={IdeaRowStyle(authUser)} bulkActionButtons={false}>
                <FunctionField label="Idea Thumbnail" render={(record) => (
                    <ImageRenderer jobInfo={record.task.job.jobInfo} />
                )}/>
                <TitleField label="Title" source="title" sortable/>
                <FunctionField label="" render={record =>(
                    <a href={`/#/job/${record.task.job.id}/show`} target="_blank" rel="noreferrer">View Project</a>
                )} />
                <ReferenceField source="staffId" reference="User">
                    <TextField label="Creator" source="firstName" />
                </ReferenceField>
                <TextField source="status" />
                <FunctionField label="Actions" render={(record) => {
                    switch (record.status) {
                        case 'Open':
                            return authUser.id === record.staffId?(
                                <ButtonGroup>
                                    <Button variant="contained" color="primary" onClick={()=> window.open(`https://docs.google.com/document/d/${record.googleId}/edit`,"_blank")}> Edit Outline</Button>
                                    <Button variant="contained" color="primary" onClick={() => handleSubmit(record.id)}>Submit</Button>
                                </ButtonGroup>
                                ):(
                                <span>Waiting for Submission</span>);                        
                        case 'Available': {
                            if(record.staffId === authUser.id && (!record.info || !record.info.taskId)){
                                return <Button variant="contained" sx={{backgroundColor: 'yellow', color: 'black', '&:hover': {backgroundColor: 'yellow'}}} onClick={() =>handleJoinJob(record)} >Join Job</Button> 
                            }
                            else return <span>Available</span>
                        }
                        case 'Completed': return <span>Completed</span>
                        default:
                            return <span>Unknown</span>;
                    }
                }} />
                <FunctionField render={(record) => <CustomRush record={record}  setIsLoading={setIsLoading} userTeams={userTeams}/>} />
            </Datagrid>
        </List>
    );
};

export default OutLine;
