// white :nothing, yellow: Working, orange:review, green : Completed, pink : Redo, grey: Rejected, blue: Posted,  red: past due days, purple: rush
const StaffRowStyle =(authUser, userTeams) => (record, index) => { 
    const isStaff = record.staffId === authUser.id;
    if(record.staffId === authUser.id && record.task.rush) return { backgroundColor: '#c9a0dc' }; //light pink
    const indexInTeam = userTeams.indexOf(record.task.job.teamId);
    const isLeader = authUser.teams[indexInTeam] && authUser.teams[indexInTeam].role > 1;
    switch (record.status) {
        case 'Redo': {
            if(isStaff) return { backgroundColor: '#ffc0cb' }; //pink
            return null;
        }
        case 'Open':{
            if((record.staffId === authUser.id)|| (record.task.type ===5 && isLeader)) return { backgroundColor: '#f5f774' }; //light yellow
            return null;
        }
        case 'Submitted':{    
            if(record.task.type ===4 && isLeader) return { backgroundColor: '#f5f774' }; //todo - light yellow   
            if(record.staffId === authUser.id) return { backgroundColor: 'orange' }; // Waiting -light yellow
            return null;
        }
        case 'Completed': return { backgroundColor: '#6df7a4' };
        default: return null;
    }
};

const VideoRowStyle = (record, index) => {
    switch (record.status) {
        case 'Working':
            return { backgroundColor: '#f5f774' }; //light yellow
        case 'Ready to Post':
            return { backgroundColor: '#6df7a4' }; //light green
        case 'Posted':
            return { backgroundColor: '#00ffff' }; //light blue
        case 'Copyright':
            return { backgroundColor: '#ff0000' }; //red
        case 'Flagged':
            return { backgroundColor: '#ff00ff' }; //magenta
        case 'Compiled':
            return { backgroundColor: '#ffccff' }; //light pink
        default:
            return {};
    }
};

const JobRowStyle = (record, index) => {
    switch (record.status) {
        case 'Open':
            return { backgroundColor: '#f5f774' }; //light yellow
        case 'Ready to Post':
            return { backgroundColor: '#6df7a4' }; //light green
        case 'Completed': //Posted
            return { backgroundColor: '#00ffff' }; // blue
        default:
            return {};
    }
};

const IdeaRowStyle =(authUser) => (record) => {
    switch(record.status){
        case 'Completed': return { backgroundColor: '#6df7a4' }; //light green
        case 'Available':
        case 'Open': {
            if(record.staffId === authUser.id) return { backgroundColor: '#f5f774' }; //light yellow
            return null;
        }
        default: return null;
    }
}

const ScriptRowStyle =( authUser,isManager) => (record, index) => {
    switch(record.status){
        case 'Completed': return { backgroundColor: '#6df7a4' }; //light green
        case 'Approved': {
            if(isManager) return { backgroundColor: '#f5f774' }; //light yellow
            return { backgroundColor: '#00ffff' }; //light blue
        }
        case 'Open': 
        case 'Redo':
        case 'Available':{
            if(record.staffId === authUser.id) return { backgroundColor: '#f5f774' }; //light yellow
            return null;
        }
        case 'Submitted':{
            if(isManager)  return { backgroundColor: '#f5f774' }; //light yellow
            if(record.staffId === authUser.id) return { backgroundColor: 'orange' }; 
            return null;
        }
        
        default: return null;
    }
};


export {StaffRowStyle, VideoRowStyle, JobRowStyle, IdeaRowStyle, ScriptRowStyle};