import React, { useState, useContext,  useCallback   } from 'react';
import { 
    useRecordContext, 
    useRefresh,
    SimpleForm,
    SelectInput,
    useListContext
 } from 'react-admin';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from '../axiosConfig';
import { UserContext } from "../auth/UserContext";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const StatusField = ({ source, setLoading, allStatus }) => {
    const record = useRecordContext();
    const { selectedIds } = useListContext(); 
    const hasSelection = selectedIds && selectedIds.length > 0;
    const [isEditing, setIsEditing] = useState(false);
    const [status, setStatus] =  useState(record.status.length>0? record.status[0] : '');
    const refresh = useRefresh();
    const authUser = useContext(UserContext);
    const canPost = record.team.info.userPosts && record.team.info.userPosts.includes(authUser.id);

    const handleDoubleClick = () => {
        if(hasSelection) return;
        if (canPost) {setIsEditing(true)}
    };    

    const handleSaveVideoStatus = async () => {
        if(status === record.status[0]){
            setIsEditing(false);
            return;
        }
        setLoading(true);
        try {
            setIsEditing(false);
            await axios.put(`${process.env.REACT_APP_API_URL}api/video/${record.id}`, { status: [status]});            
        } catch (error) {
            console.error(error);
        } finally  {
            refresh();            
            setLoading(false);
        }
    };

    const handleCancel = useCallback(() => {
        setIsEditing(false);
        setStatus(record.status.length>0? record.status[0] : '');
    },[record.status]);

    return(
        <>
            <Dialog
                open={isEditing}
                onClose={handleCancel}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Select Status:</DialogTitle>
                <DialogContent >
                    <SimpleForm toolbar={null}>
                        <SelectInput
                            source="status"
                            label="Video Status"
                            choices={allStatus}
                            onChange={(e) => setStatus(e.target.value)}
                            defaultValue={record.status[0]}
                        />
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup>
                        <Button onClick={handleSaveVideoStatus} variant="contained" color="primary" startIcon={<SaveIcon />}>Save</Button>
                        <Button onClick={handleCancel} variant="contained" color="error" startIcon={<CancelIcon />} > Cancel </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
            
            {status === ''?            
                <span onClick={handleDoubleClick} style={{ cursor: 'pointer' }}>{canPost?'Click To Set':''}</span>
                :
                <div onDoubleClick={handleDoubleClick} style={{ cursor: 'pointer' }} title={canPost? 'DB Click to Change': ''}>{status}</div>}

        </>
    )
};

export default  StatusField;
